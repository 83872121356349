import { createApp } from 'vue';
import App from './App.vue';

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import { store } from './store';
import router from './route';

import './index.css'

const app = createApp(App);

app.use(router);
app.use(store);
app.component("FullScreenLoader", Loading);
app.config.errorHandler = () => null;
app.config.warnHandler = () => null;

app.mount('#app')

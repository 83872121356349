<template>
  <div class="text-slate-600">
    <router-view></router-view>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "App",
  mounted() {
    AOS.init()
  }
};
</script>

import Resource from "@/services/resources";
// import { METHODS } from "@/services/const";
// import crypto from "@/services/crypto";

export const page = {
    namespaced: true,
    state: () => ({
        MobileNavigation: false,
        Countries: [],
        Currencies: [],
        Plans: [],
        Durations: []
    }),
    mutations: {
        setCountriesState (state, data) {
            state.Countries = data
        },
        setCurrenciesState (state, data) {
            state.Currencies = data
        },
        setPlansState (state, data) {
            state.Plans = data
        },
        setDurationsState (state, data) {
            state.Durations = data
        },
    },
    actions: {
        fetchCountriesAction({commit}) {
            return new Promise((resolve, reject) => {
                new Resource('api/v1/countries').list({})
                .then(({data}) => {
                    commit('setCountriesState', data.data)
                    resolve(true)
                })
                .catch(error => reject(error))
            })
        },
        fetchCurrenciesAction({commit}) {
            return new Promise((resolve, reject) => {
                new Resource('api/v1/currencies/all').list({})
                .then(({data}) => {
                    commit('setCurrenciesState', data.data)
                    resolve(true)
                })
                .catch(error => reject(error))
            })
        },
        fetchSubscriptionsAction({commit}) {
            return new Promise((resolve, reject) => {
                new Resource('api/v1/subscription_durations').list({})
                .then(({data}) => {
                    commit('setDurationsState', data)
                    resolve(true)
                })
                .catch(error => reject(error))
            })
        },
        fetchPlansAction({commit}, params) {
            // con
            return new Promise((resolve, reject) => {
                new Resource(`api/v1/subscription_plans`).list(params)
                .then(({data}) => {
                    commit('setPlansState', data)
                    resolve(true)
                })
                .catch(error => reject(error))
            })
        },
        
    },
    getters: {
        getCountries(state) {
            return state.Countries
        },
        getCurrencies(state) {
            return state.Currencies
        },
        getPlans(state) {
            return state.Plans
        },
        getDurations(state) {
            return state.Durations
        },
    }
};
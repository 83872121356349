import Resource from "@/services/resources";

export const auth = {
    namespaced: true,
    state: () => ({
        user: {}
    }),
    mutations: {
        setUser(state, data) {
            state.user = data
        },
    },
    actions: {
        registerUser({ commit }, data) {
            return new Promise((resolve, reject) => {
                new Resource('api/v1/company_requests').store(data)
                    .then(res => {
                        commit('setUser', res.data)
                        resolve(res.data)
                    })
                    .catch(error => reject(error))
            })
        }
    }
}
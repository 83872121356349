import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            component: () => import('./layouts/LandingLayout.vue'),
            children: [
                {
                    path: "",
                    name: "Home",
                    component: () => 
                      import ("./pages/Home.vue")
                },
                {
                    path: "pricing",
                    name: "Pricing",
                    component: () => 
                      import ("./pages/Pricing.vue")
                },
                {
                    path: "contact-us",
                    name: "ContactUs",
                    component: () => 
                      import ("./pages/ContactUs.vue")
                },
            ]
        },
        {
            path: "/register",
            name: "Register",
            component: () => 
              import ("./pages/Register.vue")
        },
        {
            path: "/:catchAll(.*)",
            redirect: "/",
        },
    ]
});


export default router;